<template>
  <v-dialog v-model="show" max-width="600" @input="handleDialog">
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <v-form @submit.prevent="handleSubmit(onSubmit)">
          <v-toolbar dark>
            <v-toolbar-title>Benutzer bearbeiten</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <base-alert v-if="error">{{ error.message }}</base-alert>
            <v-text-field label="Anzeigename" v-model="displayName" />
            <ValidationProvider name="Telefon" rules="phone" v-slot="{ errors }">
              <v-text-field label="Telefon" v-model="phone" :error-messages="errors" />
            </ValidationProvider>
            <v-switch v-model="changePassword" label="Passwort muss beim nächsten Login geändert werden" />
            <ValidationProvider name="Berechtigung" rules="required" v-slot="{ errors }">
              <v-radio-group v-model="rolesStr" row :error-messages="errors">
                <v-radio v-for="(userRole, index) in userRolesItems" :key="`user-role-${index}`" :label="userRole.text" :value="userRole.value" />
              </v-radio-group>
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="onClose" :disabled="loading">Abbrechen</v-btn>
            <v-btn type="submit" color="primary" :loading="loading" :disabled="loading">Speichern</v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import UserRoles from '@/helpers/userRoles';

const { mapFields } = createHelpers({
  getterType: 'user/field',
  mutationType: 'user/updateField',
});

export default {
  components: { ValidationObserver, ValidationProvider },

  data: () => ({
    userRolesItems: UserRoles,
  }),

  computed: {
    ...mapGetters({
      dialog: 'user/dialog/update',
      loading: 'loading/get',
      error: 'error/get',
    }),
    ...mapFields(['displayName', 'phone', 'changePassword', 'roles']),
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    rolesStr: {
      get() {
        return (this.roles && this.roles.length > 0) ? this.roles[0] : 'editor';
      },
      set(value) {
        this.roles = [value];
      },
    },
  },

  watch: {
    dialog: {
      handler(value, oldValue) {
        if (value === false && oldValue === true) {
          this.onClose();
        }
      },
    },
  },

  methods: {
    onSubmit() {
      this.$store.dispatch('user/update', {
        displayName: this.displayName,
        phone: this.phone,
        changePassword: this.changePassword,
        roles: this.roles,
      });
    },
    onClose() {
      this.$store.commit('user/dialog/update', false);
      this.$store.commit('user/reset');
      this.$store.commit('error/set', null);
      this.$refs.form.reset();
    },
    handleDialog(value) {
      if (value === false) {
        this.onClose();
      }
    },
  },
};
</script>
