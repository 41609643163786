<template>
  <div class="mt-3">
    <base-title v-model="search">Benutzer</base-title>
    <v-card>
      <v-tooltip bottom open-delay="500" v-if="$can('create', 'User')">
        <template v-slot:activator="{ on }">
          <v-btn color="#64A175" dark small absolute top right fab @click="createUser" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Eine neue Benutzer hinzufügen</span>
      </v-tooltip>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [10, 20, -1] }"
        :items-per-page.sync="itemsPerPage"
        virtual-rows
        sort-by="email"
      >
        <template v-slot:item.email="{ item }">
          <div :class="{ 'grey--text': item.disabled }">
            <v-avatar color="primary" size="36">
              <span class="white--text">{{ item.shortName }}</span>
            </v-avatar>
            <span class="ml-4">{{ item.email }}</span>
          </div>
        </template>
        <template v-slot:item.displayName="{ item }">
          <div :class="{ 'grey--text': item.disabled }">{{ item.displayName }}</div>
        </template>
        <template v-slot:item.phone="{ item }">
          <div :class="{ 'grey--text': item.disabled }">{{ item.phone }}</div>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <div :class="{ 'grey--text': item.disabled }">{{ item.createdAt | formatDate }}</div>
        </template>
        <template v-slot:item.roles="{ item }">
          <v-chip v-for="(role, index) in item.roles" :key="`user-role-${index}`" small :disabled="item.disabled">{{ role | getText }}</v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="updateUser(item)" v-if="$can('update', 'User')">
                <v-list-item-title>Bearbeiten</v-list-item-title>
              </v-list-item>
              <v-list-item @click="resetPassword(item)" v-if="$can('password', 'User')">
                <v-list-item-title>Passwort zurücksetzten</v-list-item-title>
              </v-list-item>
              <v-list-item @click="disableUser(item)" v-if="$can('disable', item)">
                <v-list-item-title>{{ item.disabled ? "Aktivieren" : "Deaktivieren" }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteUser(item)" v-if="$can('delete', item)">
                <v-list-item-title>Löschen</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <Create />
    <Update />
    <ResetPassword />
    <Disable />
    <Delete />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { getText } from '@/helpers/userRoles';

export default {
  components: {
    Create: () => import(/* webpackChunkName: "user" */ '@/components/scoped/user/Create.vue'),
    Update: () => import(/* webpackChunkName: "user" */ '@/components/scoped/user/Update.vue'),
    ResetPassword: () => import(/* webpackChunkName: "user" */ '@/components/scoped/user/ResetPassword.vue'),
    Disable: () => import(/* webpackChunkName: "user" */ '@/components/scoped/user/Disable.vue'),
    Delete: () => import(/* webpackChunkName: "user" */ '@/components/scoped/user/Delete.vue'),
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    search: '',
    headers: [
      { text: 'Benutzername', value: 'email' },
      { text: 'Anzeigename', value: 'displayName' },
      { text: 'Telefon', value: 'phone' },
      { text: 'Berechtigung', value: 'roles', sortable: false },
      { text: 'Erstellt', value: 'createdAt', sortable: false },
      { value: 'action', align: 'right', sortable: false },
    ],
  }),

  computed: {
    itemsPerPage: {
      get() {
        return Number.parseInt(localStorage.getItem('table-user-items'), 10) || 10;
      },
      set(value) {
        localStorage.setItem('table-user-items', value);
      },
    },
  },

  filters: {
    formatDate(value) {
      if (value) {
        return dayjs(value.toDate()).format('DD.MM.YYYY HH:mm:ss');
      }
      return null;
    },
    getText(value) {
      return getText(value);
    },
  },

  methods: {
    createUser() {
      this.$store.commit('user/dialog/create', true);
    },
    updateUser(user) {
      this.$store.dispatch('user/load', user.id).then(() => {
        this.$store.commit('user/sync');
        this.$store.commit('user/dialog/update', true);
      });
    },
    resetPassword(user) {
      this.$store.commit('user/dialog/resetPassword', {
        id: user.id,
        displayName: user.displayName,
        email: user.email,
      });
    },
    disableUser(user) {
      this.$store.commit('user/dialog/disable', {
        id: user.id,
        displayName: user.displayName,
        disabled: user.disabled,
      });
    },
    deleteUser(user) {
      this.$store.commit('user/dialog/delete', {
        id: user.id,
        displayName: user.displayName,
      });
    },
  },
};
</script>
