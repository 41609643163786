var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600"},on:{"input":_vm.handleDialog},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Benutzer erstellen")])],1),_c('v-card-text',[(_vm.error)?_c('base-alert',[_vm._v(_vm._s(_vm.error.message))]):_vm._e(),_c('v-text-field',{attrs:{"label":"Anzeigename"},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}}),_c('ValidationProvider',{attrs:{"name":"E-Mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-Mail","type":"email","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Telefon","rules":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Telefon","error-messages":errors},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Passwort","rules":"min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Passwort","error-messages":errors,"append-icon":"mdi-settings","counter":""},on:{"click:append":_vm.generatePassword},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('v-switch',{attrs:{"label":"Passwort muss beim nächsten Login geändert werden"},model:{value:(_vm.changePassword),callback:function ($$v) {_vm.changePassword=$$v},expression:"changePassword"}}),_c('ValidationProvider',{attrs:{"name":"Berechtigung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","error-messages":errors},model:{value:(_vm.rolesStr),callback:function ($$v) {_vm.rolesStr=$$v},expression:"rolesStr"}},_vm._l((_vm.userRolesItems),function(userRole,index){return _c('v-radio',{key:("user-role-" + index),attrs:{"label":userRole.text,"value":userRole.value}})}),1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.onClose}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v("Hinzufügen")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }