var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('base-title',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_vm._v("Benutzer")]),_c('v-card',[(_vm.$can('create', 'User'))?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#64A175","dark":"","small":"","absolute":"","top":"","right":"","fab":""},on:{"click":_vm.createUser}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,1998448626)},[_c('span',[_vm._v("Eine neue Benutzer hinzufügen")])]):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [10, 20, -1] },"items-per-page":_vm.itemsPerPage,"virtual-rows":"","sort-by":"email"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{ 'grey--text': item.disabled }},[_c('v-avatar',{attrs:{"color":"primary","size":"36"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.shortName))])]),_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(item.email))])],1)]}},{key:"item.displayName",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{ 'grey--text': item.disabled }},[_vm._v(_vm._s(item.displayName))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{ 'grey--text': item.disabled }},[_vm._v(_vm._s(item.phone))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{ 'grey--text': item.disabled }},[_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))])]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return _vm._l((item.roles),function(role,index){return _c('v-chip',{key:("user-role-" + index),attrs:{"small":"","disabled":item.disabled}},[_vm._v(_vm._s(_vm._f("getText")(role)))])})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.$can('update', 'User'))?_c('v-list-item',{on:{"click":function($event){return _vm.updateUser(item)}}},[_c('v-list-item-title',[_vm._v("Bearbeiten")])],1):_vm._e(),(_vm.$can('password', 'User'))?_c('v-list-item',{on:{"click":function($event){return _vm.resetPassword(item)}}},[_c('v-list-item-title',[_vm._v("Passwort zurücksetzten")])],1):_vm._e(),(_vm.$can('disable', item))?_c('v-list-item',{on:{"click":function($event){return _vm.disableUser(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.disabled ? "Aktivieren" : "Deaktivieren"))])],1):_vm._e(),(_vm.$can('delete', item))?_c('v-list-item',{on:{"click":function($event){return _vm.deleteUser(item)}}},[_c('v-list-item-title',[_vm._v("Löschen")])],1):_vm._e()],1)],1)]}}])})],1),_c('Create'),_c('Update'),_c('ResetPassword'),_c('Disable'),_c('Delete')],1)}
var staticRenderFns = []

export { render, staticRenderFns }