<template>
  <fragment>
    <v-row>
      <v-col cols="12" sm="6" lg="4" xl="3" v-for="item in data" :key="`list-user-${item.id}`">
        <v-card class="d-flex flex-column justify-space-between" height="100%">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title :class="{ 'grey--text': item.disabled }" v-text="item.displayName" />
              <v-card-text :class="{ 'grey--text': item.disabled }">
                <div>{{ item.email }}</div>
                <div v-if="item.phone">{{ item.phone | formatPhone }}</div>
              </v-card-text>
            </div>
            <v-avatar color="primary" size="36" class="ma-3">
              <span class="white--text">{{ item.shortName }}</span>
            </v-avatar>
          </div>
          <v-card-actions>
            <v-chip-group column>
              <v-chip v-for="(role, index) in item.roles" :key="`user-role-${index}`" :disabled="item.disabled">{{ role | getText }}</v-chip>
            </v-chip-group>
            <v-spacer />
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="updateUser(item)" v-if="$can('update', 'User')">
                  <v-list-item-title>Bearbeiten</v-list-item-title>
                </v-list-item>
                <v-list-item @click="resetPassword(item)" v-if="$can('password', 'User')">
                  <v-list-item-title>Passwort zurücksetzten</v-list-item-title>
                </v-list-item>
                <v-list-item @click="disableUser(item)" v-if="$can('disable', item)">
                  <v-list-item-title>{{ item.disabled ? "Aktivieren" : "Deaktivieren" }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteUser(item)" v-if="$can('delete', item)">
                  <v-list-item-title>Löschen</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" lg="4" xl="3" v-if="$can('create', 'User')">
        <base-card-add @click="createUser" />
      </v-col>
    </v-row>
    <Create />
    <Update />
    <ResetPassword />
    <Disable />
    <Delete />
  </fragment>
</template>

<script>
import PhoneNumber from 'awesome-phonenumber';
import { getText } from '@/helpers/userRoles';

export default {
  components: {
    Create: () => import(/* webpackChunkName: "user" */ '@/components/scoped/user/Create.vue'),
    Update: () => import(/* webpackChunkName: "user" */ '@/components/scoped/user/Update.vue'),
    ResetPassword: () => import(/* webpackChunkName: "user" */ '@/components/scoped/user/ResetPassword.vue'),
    Disable: () => import(/* webpackChunkName: "user" */ '@/components/scoped/user/Disable.vue'),
    Delete: () => import(/* webpackChunkName: "user" */ '@/components/scoped/user/Delete.vue'),
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  filters: {
    formatPhone(value) {
      const pn = new PhoneNumber(value, 'CH');
      return pn.getNumberFrom();
    },
    getText(value) {
      return getText(value);
    },
  },

  methods: {
    createUser() {
      this.$store.commit('user/dialog/create', true);
    },
    updateUser(user) {
      this.$store.dispatch('user/load', user.id).then(() => {
        this.$store.commit('user/sync');
        this.$store.commit('user/dialog/update', true);
      });
    },
    resetPassword(user) {
      this.$store.commit('user/dialog/resetPassword', {
        id: user.id,
        displayName: user.displayName,
        email: user.email,
      });
    },
    disableUser(user) {
      this.$store.commit('user/dialog/disable', {
        id: user.id,
        displayName: user.displayName,
        disabled: user.disabled,
      });
    },
    deleteUser(user) {
      this.$store.commit('user/dialog/delete', {
        id: user.id,
        displayName: user.displayName,
      });
    },
  },
};
</script>
