<template>
  <v-dialog v-model="show" max-width="600" @input="handleDialog">
    <v-card>
      <base-alert v-if="error">{{ error.message }}</base-alert>
      <v-card-title class="headline">Benutzer {{ dialog.disabled ? "aktivieren" : "deaktivieren" }}?</v-card-title>
      <v-card-text>
        <p>
          Möchten Sie den Benutzer
          <strong>«{{ dialog.displayName }}»</strong>
          wirklich {{ dialog.disabled ? "aktivieren" : "deaktivieren" }}?
        </p>
        <p>Sie können den Benutzer jeder Zeit wieder {{ !dialog.disabled ? "aktivieren" : "deaktivieren" }}.</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.stop="onClose" :disabled="loading">Abbrechen</v-btn>
        <v-btn color="error" @click.stop="onDisable" :loading="loading" :disabled="loading">{{ dialog.disabled ? "Aktivieren" : "Deaktivieren" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      dialog: 'user/dialog/disable',
      loading: 'loading/get',
      error: 'error/get',
    }),
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    onDisable() {
      this.$store.dispatch('user/disable', { id: this.dialog.id, status: !this.dialog.disabled });
    },
    onClose() {
      this.$store.commit('user/dialog/disable', false);
      this.$store.commit('error/set', null);
    },
    handleDialog(value) {
      if (value === false) {
        this.onClose();
      }
    },
  },
};
</script>
