<template>
  <v-dialog v-model="show" max-width="600" @input="handleDialog">
    <v-card v-if="dialog">
      <template v-if="auth.id === dialog.id">
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <v-form @submit.prevent="handleSubmit(onSubmit)">
            <v-toolbar dark>
              <v-toolbar-title>Passwort zurücksetzten</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <base-alert v-if="error">{{ error.message }}</base-alert>
              <ValidationProvider name="Passwort" rules="required" vid="password" v-slot="{ errors }">
                <v-text-field
                  label="Passwort"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  v-model="password"
                  @click:append="showPassword = !showPassword"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <ValidationProvider name="Passwort bestätigen" rules="required|confirmed:password" v-slot="{ errors }">
                <v-text-field label="Passwort bestätigen" type="password" v-model="confirmPassword" :error-messages="errors" />
              </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click.stop="onClose" :disabled="loading">Abbrechen</v-btn>
              <v-btn type="submit" color="primary" :loading="loading" :disabled="loading">Speichern</v-btn>
            </v-card-actions>
          </v-form>
        </ValidationObserver>
      </template>
      <template v-else>
        <v-card-title class="headline">Passwort zurücksetzten</v-card-title>
        <v-card-text>
          <p>
            Soll dem Benutzer
            <strong>«{{ dialog.displayName }}»</strong>
            ein Link zum Zurücksetzen des Passworts gesendet werden?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="onClose" :disabled="loading">Abbrechen</v-btn>
          <v-btn @click.stop="sendPasswordResetEmail" color="primary" :loading="loading" :disabled="loading">Senden</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  components: { ValidationObserver, ValidationProvider },

  data: () => ({
    password: '',
    confirmPassword: '',
    showPassword: false,
  }),

  computed: {
    ...mapGetters({
      dialog: 'user/dialog/resetPassword',
      auth: 'auth/get',
      loading: 'loading/get',
      error: 'error/get',
    }),
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    onSubmit() {
      this.$store.dispatch('user/resetPassword', { id: this.dialog.id, password: this.password });
    },
    sendPasswordResetEmail() {
      this.$store.dispatch('user/sendPasswordResetEmail', { email: this.dialog.email });
    },
    onClose() {
      this.$store.commit('user/dialog/resetPassword', false);
      this.$store.commit('error/set', null);
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.password = '';
      this.confirmPassword = '';
      this.showPassword = false;
    },
    handleDialog(value) {
      if (value === false) {
        this.onClose();
      }
    },
  },
};
</script>
