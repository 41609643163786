import _ from 'lodash';

const data = [
  {
    text: 'Administrator',
    value: 'admin',
  },
  {
    text: 'Editor',
    value: 'editor',
  },
];

export default data;

export const getText = (value) => {
  const item = _.find(data, ['value', value]);
  return item.text;
};
